import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Misc from "../Services/Misc";
import { BASE_URL } from "const";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "components/CustomButton/CustomButton.jsx";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import moment from "moment";
const thArr = [
  "First Name",
  "Last Name",
  // "Mobile Number",
  "Mobile WhatsApp",
  "Amount",
  "E-mail",
  // "SMS",
  // "WhatsApp",
  "Paid Date",
  // "Pay Link",
  // "Copy Link",
];
class TableList extends Component {
  state = {
    transactions: [],
    filtteredArr: [],
    isFilter: false,
    start_date: "",
    end_date: "",
    total: 0,
    filterTotal: 0
  };
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  componentDidMount() {
    Misc.getMerchantTransactions().then((res) => {
      var trans = [];
      let totalAmount = 0
      for (let i = 0; i < res.length; i++) {
        if (res[i].is_paid === true) {
          totalAmount = totalAmount + res[i].amount
        }
        trans.push(res[i]);
      }
      console.log(res.length, "TRANS LENGTH")
      this.setState({
        transactions: [...res.filter((data) => data.is_paid === true)],
        total: totalAmount
      });
    });
  }
  search() {
    let from = Date.parse(this.state.start_date);
    let to = Date.parse(this.state.end_date);
    let today = new Date();
    let arr = this.state.transactions;
    let filtteredArr = arr.filter(
      (data) =>
        Date.parse(data.created_at) >= from && to >= Date.parse(data.created_at)
    );
    let totalAmount = 0
    for (let i = 0; i < filtteredArr.length; i++) {
      if (filtteredArr[i].is_paid === true) {
        totalAmount = totalAmount + filtteredArr[i].amount
      }
    }
    this.setState({
      filtteredArr: [...filtteredArr],
      isFilter: true,
      filterTotal: totalAmount
    })
  }
  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Paid Transactions"
                category={"Total" + " : " + this.state.total + " " + "EGP"}
                ctTableFullWidth
                ctTableResponsive
                content={
                  <div>
                    <div className="row" style={{ margin: 20 }}>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>From</label>
                          <input
                            type="date"
                            value={this.state.first_name}
                            className="form-control"
                            placeholder="Choose Start Date"
                            name="start_date"
                            onChange={(e) => {
                              this.setState({
                                start_date: String(e.target.value),
                              });
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>To</label>
                          <input
                            type="date"
                            value={this.state.first_name}
                            className="form-control"
                            placeholder="Choose End Date"
                            name="end_date"
                            onChange={(e) =>
                              this.setState({
                                end_date: String(e.target.value),
                              })
                            }
                          ></input>
                        </div>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        {" "}
                        <Button
                          bsStyle="info"
                          pullRight
                          onClick={() => {
                            this.search();
                          }}
                          fill
                        >
                          Filter
                        </Button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4"> {!this.state.isFilter && (
                        <h4 style={{ marginInlineStart: 16 }}>Sub Total: {this.state.total}</h4>
                      )}
                        {this.state.isFilter && (
                          <h4 style={{ marginInlineStart: 16 }}>Sub Total: {this.state.filterTotal}</h4>
                        )}</div>
                      <div className="col-md-8"><ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button"
                        table="table-to-xls"
                        filename="Onelink-payments"
                        sheet="tablexls"
                        buttonText="Download as XLS"
                        bsStyle="info"
                        pullRight
                        fill
                      /></div>
                    </div>


                    <div id="customContainer">

                      <Table id="table-to-xls">
                        <thead>
                          <tr>
                            {thArr.map((prop, key) => {
                              return <th key={key}>{prop}</th>;
                            })}
                          </tr>
                        </thead>
                        {!this.state.isFilter && (
                          <tbody>
                            {this.state.transactions.map((data, key) => {
                              return (
                                <tr key={data.id}>
                                  <td>{data.first_name}</td>
                                  <td>{data.last_name}</td>
                                  <td>{data.mobile_number}</td>
                                  {/* <td>{data.mobile_whatsApp}</td> */}
                                  <td>
                                    {" "}
                                    {/* {this.numberWithCommas(data.amount)} */}
                                    {parseFloat(data.amount)}
                                  </td>
                                  <td>{data.email}</td>
                                  <td>
                                    <span>
                                      {moment(data.paid_at).format(
                                        "MMM DD , YYYY"
                                      )}
                                    </span> {" "}
                                    <span>
                                      {moment(data.paid_at).format("hh:mm a")}
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        )}

                        {this.state.isFilter && (
                          <tbody>
                            {this.state.filtteredArr.map((data, key) => {
                              return (
                                <tr key={data.id}>
                                  <td>{data.first_name}</td>
                                  <td>{data.last_name}</td>
                                  <td>{data.mobile_number}</td>
                                  {/* <td>{data.mobile_whatsApp}</td> */}
                                  <td>
                                    {" "}
                                    {/* {this.numberWithCommas(data.amount)} */}
                                    {parseFloat(data.amount)}
                                  </td>
                                  <td>{data.email}</td>
                                  <td>
                                    <span>
                                      {moment(data.paid_at).format(
                                        "MMM DD , YYYY"
                                      )}
                                    </span>
                                    {" "}
                                    <span>
                                      {moment(data.paid_at).format("hh:mm a")}
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        )}
                      </Table>
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default TableList;
