import { gql } from "apollo-boost";
const MERCHANT_TRANSACTIONS = gql`
  query merchantTransactions {
    merchantTransactions {
      id
      type
      first_name
      last_name
      mobile_number
      mobile_whatsApp
      subAccount {
        id
      }
      email
      amount
      paid_at
      is_smsSent
      is_emailSent
      is_whatsAppSent
      is_expired
      is_paid
      notices
      created_at
      updated_at
    }
  }
`;

const GET_TRANS = gql`
  query getTrans($transaction_id: ID!) {
    getTransaction(transaction_id: $transaction_id) {
      id
      amount
      first_name
      last_name
      email
      is_paid
      delivery_mobile
      paid_at
      added_by {
        id
        first_name
        email
        last_name
        merchant {
          id
          name
          address_line1
          address_line2
          upg {
            id
            mID
            tID
            secureHash
            returnUrl
            notificationUrl
          }
          megs {
            id
            merchantID
            merchantName
            api_username
            api_password
            returnUrl
            currency
            notificationUrl
          }
          mobile_number
          currency
        }
      }
    }
  }
`;

const GET_MERCHANTS = gql`
  query merchants {
    merchants {
      id
      name
      email
      currency
      mobile_number
      upg {
        id
        mID
        tID
        secureHash
        returnUrl
      }
      megs {
        id
        merchantID
        merchantName
        api_username
        api_password
        returnUrl
      }
    }
  }
`;

const GET_USERS = gql`
  query allUsers {
    allUsers {
      id
      first_name
      last_name
      email
      mobile_number
      merchant {
        name
        email
        megs {
          merchantID
          merchantName
        }
        upg {
          mID
          tID
        }
      }
    }
  }
`;

export const queries = {
  MERCHANT_TRANSACTIONS,
  GET_TRANS,
  GET_MERCHANTS,
  GET_USERS,
};
