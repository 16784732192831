import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Misc from "../Services/Misc";
import { BASE_URL } from "const";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "components/CustomButton/CustomButton.jsx";
import moment from "moment";
const thArr = [
  "First Name",
  "Last Name",
  // "Mobile Number",
  "Mobile WhatsApp",
  "Amount",
  "E-mail",
  "SMS",
  // "WhatsApp",
  // "Is Paid",
  "Pay Link",
  "Copy Link",
];
class Typography extends Component {
  state = {
    transactions: [],
    filtteredArr: [],
    start_date: "",
    end_date: "",
  };
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  search() {
    let from = Date.parse(this.state.start_date);
    let to = Date.parse(this.state.end_date);
    let today = new Date();
    let arr = this.state.transactions;
    let filtteredArr = arr.filter(
      (data) =>
        Date.parse(data.created_at) <= from && to >= Date.parse(data.created_at)
    );
  }
  componentDidMount() {
    Misc.getMerchantTransactions().then((res) => {
      var trans = [];
      for (let i = 0; i < res.length; i++) {
        trans.push(res[i]);
      }
      this.setState({
        transactions: [
          ...res.filter(
            (data) => data.is_paid === false || data.is_paid === null
          ),
        ],
      });
    });
  }
  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Unpaid Transactions"
                // category="Here is a subtitle for this table"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <div className="">

                    <div id="customContainer">
                      <Table>
                        <thead>
                          <tr>
                            {thArr.map((prop, key) => {
                              return <th key={key}>{prop}</th>;
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.transactions.map((data, key) => {
                            return (
                              <tr key={data.id}>
                                <td>{data.first_name}</td>
                                <td>{data.last_name}</td>
                                <td>{data.mobile_number}</td>
                                {/* <td>{data.mobile_whatsApp}</td> */}
                                <td>
                                  {" "}
                                  E£ {this.numberWithCommas(data.amount)}{" "}
                                </td>
                                <td>{data.email}</td>
                                {/* <td>Sent</td> */}
                                <td>
                                  {data.is_smsSent && (
                                    <i
                                      style={{
                                        fontSize: 20,
                                        padding: 0,
                                        marginTop: 10,
                                      }}
                                      className="fa fa-check-circle text-success"
                                    />
                                  )}
                                  {!data.is_smsSent && (
                                    <i
                                      style={{ fontSize: 20 }}
                                      className="fa fa-times-circle text-danger"
                                    />
                                  )}
                                </td>
                                {/* <td>
                                  <i
                                    style={{
                                      fontSize: 20,
                                      padding: 0,
                                      marginTop: 10,
                                    }}
                                    className="fa fa-check-circle text-success"
                                  />
                                </td> */}
                                <td>
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`${BASE_URL}/pay_trans/${data.id}`}
                                  >
                                    payment link
                                  </a>
                                </td>
                                <td>
                                  <CopyToClipboard
                                    text={`${BASE_URL}/pay_trans/${data.id}`}
                                  >
                                    <Button bsStyle="success" pullRight fill>
                                      COPY
                                    </Button>
                                  </CopyToClipboard>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Typography;
